import http from '@/utils/http';

/**
 * 产品分类管理
 */
export default {
  getList: {
    p: 'post,/product/attr/getlist',
    r: (data) => http({ url: '/product/attr/getlist', method: 'post', data })
  },
  add: {
    p: 'post,/product/attr/add',
    r: (data) => http({ url: '/product/attr/add', method: 'post', data })
  },
  update: {
    p: 'post,/product/attr/update',
    r: (data) => http({ url: '/product/attr/update', method: 'post', data })
  },
  del: {
    p: 'post,/product/attr/delete',
    r: (data) => http({ url: '/product/attr/delete', method: 'post', data })
  },
  getOne: {
    p: 'post,/product/attr/getOne',
    r: (data) => http({ url: '/product/attr/getone', method: 'post', data })
  }
};
