<template>
  <div :class="{ 'page-class': !$store.getters.tableConfig.card }">
    <avue-crud
        :data="tableData"
        :option="tbleOption"
        @row-save="handleSubmit"
        @row-update="rowUpdate"
        @refresh-change="getList"
        @row-del="rowDel"
        @on-load="onLoad"
        @search-change="searchChange"
        @search-reset="searchReset"
        :page.sync="page"
    >
      <template slot-scope="{ type, size, row, index }" slot="menu">
        <el-button
            icon="el-icon-s-operation"
            :size="size"
            :type="type"
            @click.stop="clickLink(row, index)"
        >属性列表</el-button
        >
        <el-button
            icon="el-icon-delete"
            :size="size"
            :type="type"
            @click.stop="rowDel(row, index)"
        >删除</el-button
        >
      </template>
    </avue-crud>
  </div>
</template>
<script>
import List from './list';

export default List;
</script>
